.login-container {
  position: relative;
  height: 100vh;
  background-position: center;
  background-size: cover;

  h1 {
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .login-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @bg: rgba(255, 255, 255, 1);

    .left-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 100px;
      padding: 100px 0;
      min-width: 400px;
      height: 100%;

      .xxd-name {
        padding-left: 100px;
        
        .logo {
          width: 115px;
          fill: white;
        }
      }

      .desc {
        padding-left: 100px;
        font-size: 28px;
        font-weight: 300;
        color: white;
        letter-spacing: 8px;
      }

      .info {
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        max-width: 552px;

        .consultant-box {
          margin-top: 30px;

          .entry-consultant {
            margin-left: 45px;
            font-size: 14px;
            font-weight: 500;
            color: white;

            &::after {
              content: '';
              display: inline-block;
              margin-left: 5px;
              width: 8px;
              height: 8px;
              border-top: 1px solid white;
              border-right: 1px solid white;
              transform: rotate(45deg);
              vertical-align: middle;
            }
          }
        }

        .item-box {
          padding: 24px 0 24px 100px;
          min-width: 250px;

          &.active {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);

            .title {
              opacity: 1;
            }

            .content-text {
              opacity: 0.7;
            }
          }

          .title {
            padding-bottom: 12px;
            font-size: 16px;
            font-weight: 500;
            opacity: 0.5;
            color: white;
          }

          .content-text {
            white-space: pre-wrap;
            color: white;
            opacity: 0.3;
            font-size: 14px;

            .text-item {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .form {
      margin-right: 260px;
      padding: 56px 40px 48px;
      min-width: 330px;
      background-color: @bg;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .form-item-container {
        padding: 0 16px;
      }

      .ant-input {
        padding: 0 80px 0 0;
        height: 42px;
        line-height: 42px;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid #e4e5e6;
        color: rgba(0, 0, 0, 0.85);
        outline: none;

        &:focus {
          outline: none;
          box-shadow: none;
          border-bottom-color: #1890ff;
        }
      }

      .item {
        position: relative;
        .send-btn,
        .captcha-box {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 80px;
          margin: auto 0;
          text-align: right;
          color: #1890ff;
        }
        .captcha-box {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .bottom {
      flex: 2;
    }
  }

  .send-btn {
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #39f;
    }
  }

  .submit-btn {
    margin-top: 24px;
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
}
