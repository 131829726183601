@red: #f5222d;
@orange: #faad14;
@green: #7cb305;
@blue: #1890ff;

@hidden: @red;
@disabled: #8c8c8c;
@rejected: @red;
@reviewing: @orange;
@created: #333333;
@normal: @green;
@update_reviewing: @orange;
@update_rejected: @red;
@border-color: #e8e8e8;

.oneline {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** 全局主色 */
@primary-color: #1890ff;
/** 链接色 */
@link-color: #1890ff;
/** 成功色 */
@success-color: #52c41a;
/** 警告色 */
@warning-color: #faad14;
/** 错误色 */
@error-color: #f5222d;
/** 主字号 */
@font-size-base: 14px;
/** 标题色 */
@heading-color: rgba(0, 0, 0, 0.85);
/** 主文本色 */
@text-color: rgba(0, 0, 0, 0.65);
/** 次文本色 */
@text-color-secondary: rgba(0, 0, 0, 0.45);
/** 失效色 */
@disabled-color: rgba(0, 0, 0, 0.25);
/** 组件/浮层圆角 */
@border-radius-base: 4px;
/** 边框色 */
@border-color-base: #d9d9d9;
/** 浮层阴影 */
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);

/* *******************  Colors *********************** */
.color-red1 {
  color: #fff1f0 !important;
  &:hover {
    color: lighten(#fff1f0, 10%) !important;
  }
}
.bg-red1 {
  background-color: #fff1f0 !important;
}
.color-red2 {
  color: #ffccc7 !important;
  &:hover {
    color: lighten(#ffccc7, 10%) !important;
  }
}
.bg-red2 {
  background-color: #ffccc7 !important;
}
.color-red3 {
  color: #ffa39e !important;
  &:hover {
    color: lighten(#ffa39e, 10%) !important;
  }
}
.bg-red3 {
  background-color: #ffa39e !important;
}
.color-red4 {
  color: #ff7875 !important;
  &:hover {
    color: lighten(#ff7875, 10%) !important;
  }
}
.bg-red4 {
  background-color: #ff7875 !important;
}
.color-red5 {
  color: #ff4d4f !important;
  &:hover {
    color: lighten(#ff4d4f, 10%) !important;
  }
}
.bg-red5 {
  background-color: #ff4d4f !important;
}
.color-red6 {
  color: #f5222d !important;
  &:hover {
    color: lighten(#f5222d, 10%) !important;
  }
}
.bg-red6 {
  background-color: #f5222d !important;
}
.color-red7 {
  color: #cf1322 !important;
  &:hover {
    color: lighten(#cf1322, 10%) !important;
  }
}
.bg-red7 {
  background-color: #cf1322 !important;
}
.color-red8 {
  color: #a8071a !important;
  &:hover {
    color: lighten(#a8071a, 10%) !important;
  }
}
.bg-red8 {
  background-color: #a8071a !important;
}
.color-red9 {
  color: #820014 !important;
  &:hover {
    color: lighten(#820014, 10%) !important;
  }
}
.bg-red9 {
  background-color: #820014 !important;
}
.color-red10 {
  color: #5c0011 !important;
  &:hover {
    color: lighten(#5c0011, 10%) !important;
  }
}
.bg-red10 {
  background-color: #5c0011 !important;
}
.color-volcano1 {
  color: #fff2e8 !important;
  &:hover {
    color: lighten(#fff2e8, 10%) !important;
  }
}
.bg-volcano1 {
  background-color: #fff2e8 !important;
}
.color-volcano2 {
  color: #ffd8bf !important;
  &:hover {
    color: lighten(#ffd8bf, 10%) !important;
  }
}
.bg-volcano2 {
  background-color: #ffd8bf !important;
}
.color-volcano3 {
  color: #ffbb96 !important;
  &:hover {
    color: lighten(#ffbb96, 10%) !important;
  }
}
.bg-volcano3 {
  background-color: #ffbb96 !important;
}
.color-volcano4 {
  color: #ff9c6e !important;
  &:hover {
    color: lighten(#ff9c6e, 10%) !important;
  }
}
.bg-volcano4 {
  background-color: #ff9c6e !important;
}
.color-volcano5 {
  color: #ff7a45 !important;
  &:hover {
    color: lighten(#ff7a45, 10%) !important;
  }
}
.bg-volcano5 {
  background-color: #ff7a45 !important;
}
.color-volcano6 {
  color: #fa541c !important;
  &:hover {
    color: lighten(#fa541c, 10%) !important;
  }
}
.bg-volcano6 {
  background-color: #fa541c !important;
}
.color-volcano7 {
  color: #d4380d !important;
  &:hover {
    color: lighten(#d4380d, 10%) !important;
  }
}
.bg-volcano7 {
  background-color: #d4380d !important;
}
.color-volcano8 {
  color: #ad2102 !important;
  &:hover {
    color: lighten(#ad2102, 10%) !important;
  }
}
.bg-volcano8 {
  background-color: #ad2102 !important;
}
.color-volcano9 {
  color: #871400 !important;
  &:hover {
    color: lighten(#871400, 10%) !important;
  }
}
.bg-volcano9 {
  background-color: #871400 !important;
}
.color-volcano10 {
  color: #610b00 !important;
  &:hover {
    color: lighten(#610b00, 10%) !important;
  }
}
.bg-volcano10 {
  background-color: #610b00 !important;
}
.color-orange1 {
  color: #fff7e6 !important;
  &:hover {
    color: lighten(#fff7e6, 10%) !important;
  }
}
.bg-orange1 {
  background-color: #fff7e6 !important;
}
.color-orange2 {
  color: #ffe7ba !important;
  &:hover {
    color: lighten(#ffe7ba, 10%) !important;
  }
}
.bg-orange2 {
  background-color: #ffe7ba !important;
}
.color-orange3 {
  color: #ffd591 !important;
  &:hover {
    color: lighten(#ffd591, 10%) !important;
  }
}
.bg-orange3 {
  background-color: #ffd591 !important;
}
.color-orange4 {
  color: #ffc069 !important;
  &:hover {
    color: lighten(#ffc069, 10%) !important;
  }
}
.bg-orange4 {
  background-color: #ffc069 !important;
}
.color-orange5 {
  color: #ffa940 !important;
  &:hover {
    color: lighten(#ffa940, 10%) !important;
  }
}
.bg-orange5 {
  background-color: #ffa940 !important;
}
.color-orange6 {
  color: #fa8c16 !important;
  &:hover {
    color: lighten(#fa8c16, 10%) !important;
  }
}
.bg-orange6 {
  background-color: #fa8c16 !important;
}
.color-orange7 {
  color: #d46b08 !important;
  &:hover {
    color: lighten(#d46b08, 10%) !important;
  }
}
.bg-orange7 {
  background-color: #d46b08 !important;
}
.color-orange8 {
  color: #ad4e00 !important;
  &:hover {
    color: lighten(#ad4e00, 10%) !important;
  }
}
.bg-orange8 {
  background-color: #ad4e00 !important;
}
.color-orange9 {
  color: #873800 !important;
  &:hover {
    color: lighten(#873800, 10%) !important;
  }
}
.bg-orange9 {
  background-color: #873800 !important;
}
.color-orange10 {
  color: #612500 !important;
  &:hover {
    color: lighten(#612500, 10%) !important;
  }
}
.bg-orange10 {
  background-color: #612500 !important;
}
.color-gold1 {
  color: #fffbe6 !important;
  &:hover {
    color: lighten(#fffbe6, 10%) !important;
  }
}
.bg-gold1 {
  background-color: #fffbe6 !important;
}
.color-gold2 {
  color: #fff1b8 !important;
  &:hover {
    color: lighten(#fff1b8, 10%) !important;
  }
}
.bg-gold2 {
  background-color: #fff1b8 !important;
}
.color-gold3 {
  color: #ffe58f !important;
  &:hover {
    color: lighten(#ffe58f, 10%) !important;
  }
}
.bg-gold3 {
  background-color: #ffe58f !important;
}
.color-gold4 {
  color: #ffd666 !important;
  &:hover {
    color: lighten(#ffd666, 10%) !important;
  }
}
.bg-gold4 {
  background-color: #ffd666 !important;
}
.color-gold5 {
  color: #ffc53d !important;
  &:hover {
    color: lighten(#ffc53d, 10%) !important;
  }
}
.bg-gold5 {
  background-color: #ffc53d !important;
}
.color-gold6 {
  color: #faad14 !important;
  &:hover {
    color: lighten(#faad14, 10%) !important;
  }
}
.bg-gold6 {
  background-color: #faad14 !important;
}
.color-gold7 {
  color: #d48806 !important;
  &:hover {
    color: lighten(#d48806, 10%) !important;
  }
}
.bg-gold7 {
  background-color: #d48806 !important;
}
.color-gold8 {
  color: #ad6800 !important;
  &:hover {
    color: lighten(#ad6800, 10%) !important;
  }
}
.bg-gold8 {
  background-color: #ad6800 !important;
}
.color-gold9 {
  color: #874d00 !important;
  &:hover {
    color: lighten(#874d00, 10%) !important;
  }
}
.bg-gold9 {
  background-color: #874d00 !important;
}
.color-gold10 {
  color: #613400 !important;
  &:hover {
    color: lighten(#613400, 10%) !important;
  }
}
.bg-gold10 {
  background-color: #613400 !important;
}
.color-yellow1 {
  color: #feffe6 !important;
  &:hover {
    color: lighten(#feffe6, 10%) !important;
  }
}
.bg-yellow1 {
  background-color: #feffe6 !important;
}
.color-yellow2 {
  color: #ffffb8 !important;
  &:hover {
    color: lighten(#ffffb8, 10%) !important;
  }
}
.bg-yellow2 {
  background-color: #ffffb8 !important;
}
.color-yellow3 {
  color: #fffb8f !important;
  &:hover {
    color: lighten(#fffb8f, 10%) !important;
  }
}
.bg-yellow3 {
  background-color: #fffb8f !important;
}
.color-yellow4 {
  color: #fff566 !important;
  &:hover {
    color: lighten(#fff566, 10%) !important;
  }
}
.bg-yellow4 {
  background-color: #fff566 !important;
}
.color-yellow5 {
  color: #ffec3d !important;
  &:hover {
    color: lighten(#ffec3d, 10%) !important;
  }
}
.bg-yellow5 {
  background-color: #ffec3d !important;
}
.color-yellow6 {
  color: #fadb14 !important;
  &:hover {
    color: lighten(#fadb14, 10%) !important;
  }
}
.bg-yellow6 {
  background-color: #fadb14 !important;
}
.color-yellow7 {
  color: #d4b106 !important;
  &:hover {
    color: lighten(#d4b106, 10%) !important;
  }
}
.bg-yellow7 {
  background-color: #d4b106 !important;
}
.color-yellow8 {
  color: #ad8b00 !important;
  &:hover {
    color: lighten(#ad8b00, 10%) !important;
  }
}
.bg-yellow8 {
  background-color: #ad8b00 !important;
}
.color-yellow9 {
  color: #876800 !important;
  &:hover {
    color: lighten(#876800, 10%) !important;
  }
}
.bg-yellow9 {
  background-color: #876800 !important;
}
.color-yellow10 {
  color: #614700 !important;
  &:hover {
    color: lighten(#614700, 10%) !important;
  }
}
.bg-yellow10 {
  background-color: #614700 !important;
}
.color-lime1 {
  color: #fcffe6 !important;
  &:hover {
    color: lighten(#fcffe6, 10%) !important;
  }
}
.bg-lime1 {
  background-color: #fcffe6 !important;
}
.color-lime2 {
  color: #f4ffb8 !important;
  &:hover {
    color: lighten(#f4ffb8, 10%) !important;
  }
}
.bg-lime2 {
  background-color: #f4ffb8 !important;
}
.color-lime3 {
  color: #eaff8f !important;
  &:hover {
    color: lighten(#eaff8f, 10%) !important;
  }
}
.bg-lime3 {
  background-color: #eaff8f !important;
}
.color-lime4 {
  color: #d3f261 !important;
  &:hover {
    color: lighten(#d3f261, 10%) !important;
  }
}
.bg-lime4 {
  background-color: #d3f261 !important;
}
.color-lime5 {
  color: #bae637 !important;
  &:hover {
    color: lighten(#bae637, 10%) !important;
  }
}
.bg-lime5 {
  background-color: #bae637 !important;
}
.color-lime6 {
  color: #a0d911 !important;
  &:hover {
    color: lighten(#a0d911, 10%) !important;
  }
}
.bg-lime6 {
  background-color: #a0d911 !important;
}
.color-lime7 {
  color: #7cb305 !important;
  &:hover {
    color: lighten(#7cb305, 10%) !important;
  }
}
.bg-lime7 {
  background-color: #7cb305 !important;
}
.color-lime8 {
  color: #5b8c00 !important;
  &:hover {
    color: lighten(#5b8c00, 10%) !important;
  }
}
.bg-lime8 {
  background-color: #5b8c00 !important;
}
.color-lime9 {
  color: #3f6600 !important;
  &:hover {
    color: lighten(#3f6600, 10%) !important;
  }
}
.bg-lime9 {
  background-color: #3f6600 !important;
}
.color-lime10 {
  color: #254000 !important;
  &:hover {
    color: lighten(#254000, 10%) !important;
  }
}
.bg-lime10 {
  background-color: #254000 !important;
}
.color-green1 {
  color: #f6ffed !important;
  &:hover {
    color: lighten(#f6ffed, 10%) !important;
  }
}
.bg-green1 {
  background-color: #f6ffed !important;
}
.color-green2 {
  color: #d9f7be !important;
  &:hover {
    color: lighten(#d9f7be, 10%) !important;
  }
}
.bg-green2 {
  background-color: #d9f7be !important;
}
.color-green3 {
  color: #b7eb8f !important;
  &:hover {
    color: lighten(#b7eb8f, 10%) !important;
  }
}
.bg-green3 {
  background-color: #b7eb8f !important;
}
.color-green4 {
  color: #95de64 !important;
  &:hover {
    color: lighten(#95de64, 10%) !important;
  }
}
.bg-green4 {
  background-color: #95de64 !important;
}
.color-green5 {
  color: #73d13d !important;
  &:hover {
    color: lighten(#73d13d, 10%) !important;
  }
}
.bg-green5 {
  background-color: #73d13d !important;
}
.color-green6 {
  color: #52c41a !important;
  &:hover {
    color: lighten(#52c41a, 10%) !important;
  }
}
.bg-green6 {
  background-color: #52c41a !important;
}
.color-green7 {
  color: #389e0d !important;
  &:hover {
    color: lighten(#389e0d, 10%) !important;
  }
}
.bg-green7 {
  background-color: #389e0d !important;
}
.color-green8 {
  color: #237804 !important;
  &:hover {
    color: lighten(#237804, 10%) !important;
  }
}
.bg-green8 {
  background-color: #237804 !important;
}
.color-green9 {
  color: #135200 !important;
  &:hover {
    color: lighten(#135200, 10%) !important;
  }
}
.bg-green9 {
  background-color: #135200 !important;
}
.color-green10 {
  color: #092b00 !important;
  &:hover {
    color: lighten(#092b00, 10%) !important;
  }
}
.bg-green10 {
  background-color: #092b00 !important;
}
.color-cyan1 {
  color: #e6fffb !important;
  &:hover {
    color: lighten(#e6fffb, 10%) !important;
  }
}
.bg-cyan1 {
  background-color: #e6fffb !important;
}
.color-cyan2 {
  color: #b5f5ec !important;
  &:hover {
    color: lighten(#b5f5ec, 10%) !important;
  }
}
.bg-cyan2 {
  background-color: #b5f5ec !important;
}
.color-cyan3 {
  color: #87e8de !important;
  &:hover {
    color: lighten(#87e8de, 10%) !important;
  }
}
.bg-cyan3 {
  background-color: #87e8de !important;
}
.color-cyan4 {
  color: #5cdbd3 !important;
  &:hover {
    color: lighten(#5cdbd3, 10%) !important;
  }
}
.bg-cyan4 {
  background-color: #5cdbd3 !important;
}
.color-cyan5 {
  color: #36cfc9 !important;
  &:hover {
    color: lighten(#36cfc9, 10%) !important;
  }
}
.bg-cyan5 {
  background-color: #36cfc9 !important;
}
.color-cyan6 {
  color: #13c2c2 !important;
  &:hover {
    color: lighten(#13c2c2, 10%) !important;
  }
}
.bg-cyan6 {
  background-color: #13c2c2 !important;
}
.color-cyan7 {
  color: #08979c !important;
  &:hover {
    color: lighten(#08979c, 10%) !important;
  }
}
.bg-cyan7 {
  background-color: #08979c !important;
}
.color-cyan8 {
  color: #006d75 !important;
  &:hover {
    color: lighten(#006d75, 10%) !important;
  }
}
.bg-cyan8 {
  background-color: #006d75 !important;
}
.color-cyan9 {
  color: #00474f !important;
  &:hover {
    color: lighten(#00474f, 10%) !important;
  }
}
.bg-cyan9 {
  background-color: #00474f !important;
}
.color-cyan10 {
  color: #002329 !important;
  &:hover {
    color: lighten(#002329, 10%) !important;
  }
}
.bg-cyan10 {
  background-color: #002329 !important;
}
.color-blue1 {
  color: #e6f7ff !important;
  &:hover {
    color: lighten(#e6f7ff, 10%) !important;
  }
}
.bg-blue1 {
  background-color: #e6f7ff !important;
}
.color-blue2 {
  color: #bae7ff !important;
  &:hover {
    color: lighten(#bae7ff, 10%) !important;
  }
}
.bg-blue2 {
  background-color: #bae7ff !important;
}
.color-blue3 {
  color: #91d5ff !important;
  &:hover {
    color: lighten(#91d5ff, 10%) !important;
  }
}
.bg-blue3 {
  background-color: #91d5ff !important;
}
.color-blue4 {
  color: #69c0ff !important;
  &:hover {
    color: lighten(#69c0ff, 10%) !important;
  }
}
.bg-blue4 {
  background-color: #69c0ff !important;
}
.color-blue5 {
  color: #40a9ff !important;
  &:hover {
    color: lighten(#40a9ff, 10%) !important;
  }
}
.bg-blue5 {
  background-color: #40a9ff !important;
}
.color-blue6 {
  color: #1890ff !important;
  &:hover {
    color: lighten(#1890ff, 10%) !important;
  }
}
.bg-blue6 {
  background-color: #1890ff !important;
}
.color-blue7 {
  color: #096dd9 !important;
  &:hover {
    color: lighten(#096dd9, 10%) !important;
  }
}
.bg-blue7 {
  background-color: #096dd9 !important;
}
.color-blue8 {
  color: #0050b3 !important;
  &:hover {
    color: lighten(#0050b3, 10%) !important;
  }
}
.bg-blue8 {
  background-color: #0050b3 !important;
}
.color-blue9 {
  color: #003a8c !important;
  &:hover {
    color: lighten(#003a8c, 10%) !important;
  }
}
.bg-blue9 {
  background-color: #003a8c !important;
}
.color-blue10 {
  color: #002766 !important;
  &:hover {
    color: lighten(#002766, 10%) !important;
  }
}
.bg-blue10 {
  background-color: #002766 !important;
}
.color-geekblue1 {
  color: #f0f5ff !important;
  &:hover {
    color: lighten(#f0f5ff, 10%) !important;
  }
}
.bg-geekblue1 {
  background-color: #f0f5ff !important;
}
.color-geekblue2 {
  color: #d6e4ff !important;
  &:hover {
    color: lighten(#d6e4ff, 10%) !important;
  }
}
.bg-geekblue2 {
  background-color: #d6e4ff !important;
}
.color-geekblue3 {
  color: #adc6ff !important;
  &:hover {
    color: lighten(#adc6ff, 10%) !important;
  }
}
.bg-geekblue3 {
  background-color: #adc6ff !important;
}
.color-geekblue4 {
  color: #85a5ff !important;
  &:hover {
    color: lighten(#85a5ff, 10%) !important;
  }
}
.bg-geekblue4 {
  background-color: #85a5ff !important;
}
.color-geekblue5 {
  color: #597ef7 !important;
  &:hover {
    color: lighten(#597ef7, 10%) !important;
  }
}
.bg-geekblue5 {
  background-color: #597ef7 !important;
}
.color-geekblue6 {
  color: #2f54eb !important;
  &:hover {
    color: lighten(#2f54eb, 10%) !important;
  }
}
.bg-geekblue6 {
  background-color: #2f54eb !important;
}
.color-geekblue7 {
  color: #1d39c4 !important;
  &:hover {
    color: lighten(#1d39c4, 10%) !important;
  }
}
.bg-geekblue7 {
  background-color: #1d39c4 !important;
}
.color-geekblue8 {
  color: #10239e !important;
  &:hover {
    color: lighten(#10239e, 10%) !important;
  }
}
.bg-geekblue8 {
  background-color: #10239e !important;
}
.color-geekblue9 {
  color: #061178 !important;
  &:hover {
    color: lighten(#061178, 10%) !important;
  }
}
.bg-geekblue9 {
  background-color: #061178 !important;
}
.color-geekblue10 {
  color: #030852 !important;
  &:hover {
    color: lighten(#030852, 10%) !important;
  }
}
.bg-geekblue10 {
  background-color: #030852 !important;
}
.color-purple1 {
  color: #f9f0ff !important;
  &:hover {
    color: lighten(#f9f0ff, 10%) !important;
  }
}
.bg-purple1 {
  background-color: #f9f0ff !important;
}
.color-purple2 {
  color: #efdbff !important;
  &:hover {
    color: lighten(#efdbff, 10%) !important;
  }
}
.bg-purple2 {
  background-color: #efdbff !important;
}
.color-purple3 {
  color: #d3adf7 !important;
  &:hover {
    color: lighten(#d3adf7, 10%) !important;
  }
}
.bg-purple3 {
  background-color: #d3adf7 !important;
}
.color-purple4 {
  color: #b37feb !important;
  &:hover {
    color: lighten(#b37feb, 10%) !important;
  }
}
.bg-purple4 {
  background-color: #b37feb !important;
}
.color-purple5 {
  color: #9254de !important;
  &:hover {
    color: lighten(#9254de, 10%) !important;
  }
}
.bg-purple5 {
  background-color: #9254de !important;
}
.color-purple6 {
  color: #722ed1 !important;
  &:hover {
    color: lighten(#722ed1, 10%) !important;
  }
}
.bg-purple6 {
  background-color: #722ed1 !important;
}
.color-purple7 {
  color: #531dab !important;
  &:hover {
    color: lighten(#531dab, 10%) !important;
  }
}
.bg-purple7 {
  background-color: #531dab !important;
}
.color-purple8 {
  color: #391085 !important;
  &:hover {
    color: lighten(#391085, 10%) !important;
  }
}
.bg-purple8 {
  background-color: #391085 !important;
}
.color-purple9 {
  color: #22075e !important;
  &:hover {
    color: lighten(#22075e, 10%) !important;
  }
}
.bg-purple9 {
  background-color: #22075e !important;
}
.color-purple10 {
  color: #120338 !important;
  &:hover {
    color: lighten(#120338, 10%) !important;
  }
}
.bg-purple10 {
  background-color: #120338 !important;
}
.color-magenta1 {
  color: #fff0f6 !important;
  &:hover {
    color: lighten(#fff0f6, 10%) !important;
  }
}
.bg-magenta1 {
  background-color: #fff0f6 !important;
}
.color-magenta2 {
  color: #ffd6e7 !important;
  &:hover {
    color: lighten(#ffd6e7, 10%) !important;
  }
}
.bg-magenta2 {
  background-color: #ffd6e7 !important;
}
.color-magenta3 {
  color: #ffadd2 !important;
  &:hover {
    color: lighten(#ffadd2, 10%) !important;
  }
}
.bg-magenta3 {
  background-color: #ffadd2 !important;
}
.color-magenta4 {
  color: #ff85c0 !important;
  &:hover {
    color: lighten(#ff85c0, 10%) !important;
  }
}
.bg-magenta4 {
  background-color: #ff85c0 !important;
}
.color-magenta5 {
  color: #f759ab !important;
  &:hover {
    color: lighten(#f759ab, 10%) !important;
  }
}
.bg-magenta5 {
  background-color: #f759ab !important;
}
.color-magenta6 {
  color: #eb2f96 !important;
  &:hover {
    color: lighten(#eb2f96, 10%) !important;
  }
}
.bg-magenta6 {
  background-color: #eb2f96 !important;
}
.color-magenta7 {
  color: #c41d7f !important;
  &:hover {
    color: lighten(#c41d7f, 10%) !important;
  }
}
.bg-magenta7 {
  background-color: #c41d7f !important;
}
.color-magenta8 {
  color: #9e1068 !important;
  &:hover {
    color: lighten(#9e1068, 10%) !important;
  }
}
.bg-magenta8 {
  background-color: #9e1068 !important;
}
.color-magenta9 {
  color: #780650 !important;
  &:hover {
    color: lighten(#780650, 10%) !important;
  }
}
.bg-magenta9 {
  background-color: #780650 !important;
}
.color-magenta10 {
  color: #520339 !important;
  &:hover {
    color: lighten(#520339, 10%) !important;
  }
}
.bg-magenta10 {
  background-color: #520339 !important;
}
.color-gray1 {
  color: #ffffff !important;
  &:hover {
    color: lighten(#ffffff, 10%) !important;
  }
}
.bg-gray1 {
  background-color: #ffffff !important;
}
.color-gray2 {
  color: #fafafa !important;
  &:hover {
    color: lighten(#fafafa, 10%) !important;
  }
}
.bg-gray2 {
  background-color: #fafafa !important;
}
.color-gray3 {
  color: #f5f5f5 !important;
  &:hover {
    color: lighten(#f5f5f5, 10%) !important;
  }
}
.bg-gray3 {
  background-color: #f5f5f5 !important;
}
.color-gray4 {
  color: #e8e8e8 !important;
  &:hover {
    color: lighten(#e8e8e8, 10%) !important;
  }
}
.bg-gray4 {
  background-color: #e8e8e8 !important;
}
.color-gray5 {
  color: #d9d9d9 !important;
  &:hover {
    color: lighten(#d9d9d9, 10%) !important;
  }
}
.bg-gray5 {
  background-color: #d9d9d9 !important;
}
.color-gray6 {
  color: #bfbfbf !important;
  &:hover {
    color: lighten(#bfbfbf, 10%) !important;
  }
}
.bg-gray6 {
  background-color: #bfbfbf !important;
}
.color-gray7 {
  color: #8c8c8c !important;
  &:hover {
    color: lighten(#8c8c8c, 10%) !important;
  }
}
.bg-gray7 {
  background-color: #8c8c8c !important;
}
.color-gray8 {
  color: #595959 !important;
  &:hover {
    color: lighten(#595959, 10%) !important;
  }
}
.bg-gray8 {
  background-color: #595959 !important;
}
.color-gray9 {
  color: #262626 !important;
  &:hover {
    color: lighten(#262626, 10%) !important;
  }
}
.bg-gray9 {
  background-color: #262626 !important;
}
.color-gray10 {
  color: #000000 !important;
  &:hover {
    color: lighten(#000000, 10%) !important;
  }
}
.bg-gray10 {
  background-color: #000000 !important;
}
.color-primary1 {
  color: #e6f7ff !important;
  &:hover {
    color: lighten(#e6f7ff, 10%) !important;
  }
}
.bg-primary1 {
  background-color: #e6f7ff !important;
}
.color-primary2 {
  color: #bae7ff !important;
  &:hover {
    color: lighten(#bae7ff, 10%) !important;
  }
}
.bg-primary2 {
  background-color: #bae7ff !important;
}
.color-primary3 {
  color: #91d5ff !important;
  &:hover {
    color: lighten(#91d5ff, 10%) !important;
  }
}
.bg-primary3 {
  background-color: #91d5ff !important;
}
.color-primary4 {
  color: #69c0ff !important;
  &:hover {
    color: lighten(#69c0ff, 10%) !important;
  }
}
.bg-primary4 {
  background-color: #69c0ff !important;
}
.color-primary5 {
  color: #40a9ff !important;
  &:hover {
    color: lighten(#40a9ff, 10%) !important;
  }
}
.bg-primary5 {
  background-color: #40a9ff !important;
}
.color-primary6 {
  color: #1890ff !important;
  &:hover {
    color: lighten(#1890ff, 10%) !important;
  }
}
.bg-primary6 {
  background-color: #1890ff !important;
}
.color-primary7 {
  color: #096dd9 !important;
  &:hover {
    color: lighten(#096dd9, 10%) !important;
  }
}
.bg-primary7 {
  background-color: #096dd9 !important;
}
.color-primary8 {
  color: #0050b3 !important;
  &:hover {
    color: lighten(#0050b3, 10%) !important;
  }
}
.bg-primary8 {
  background-color: #0050b3 !important;
}
.color-primary9 {
  color: #003a8c !important;
  &:hover {
    color: lighten(#003a8c, 10%) !important;
  }
}
.bg-primary9 {
  background-color: #003a8c !important;
}
.color-primary10 {
  color: #002766 !important;
  &:hover {
    color: lighten(#002766, 10%) !important;
  }
}
.bg-primary10 {
  background-color: #002766 !important;
}
