@import './var.less';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @text-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 1) inset !important;
}

// 浮动相关
// 清除浮动
.clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.tip {
  font-size: 12px;
  color: darkorange;
}

.display-block {
  display: block;
}

.ant-form-extra {
  color: darkorange;
  font-size: 12px;
}

.ant-list-item-meta-content {
  flex: 1;
}

.ant-list-item-meta-title > a {
  color: @text-color;
}

img {
  image-orientation: from-image;
}

hr {
  display: block;
  margin: 16px 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid @border-color;
}

.skeleton-box {
  padding: 24px 16px;
  background-color: white;
}

.cur {
  cursor: pointer;
}
