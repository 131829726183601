.container-international-school-item-list {
  .span-box {
    > * {
      margin-left: 8px;
    }
    > *:first-child {
      margin-left: 0;
    }
  }
  .batch-box {
    margin-bottom: 16px;

    > * {
      margin-left: 8px;
    }
    > *:first-child {
      margin-left: 0;
    }
  }

  .btn {
    display: inline-block;
    padding: 6px 8px;
    cursor: pointer;
  }

  .intschool-image {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}
